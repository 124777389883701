  @import "ngx-sharebuttons/themes/variables.scss";
  @import "ngx-sharebuttons/themes/main.scss";
  @import "ngx-sharebuttons/themes/modern/modern-light-theme.scss";
  @import "ngx-sharebuttons/themes/default/default-theme.scss";
  @import "@angular/material/prebuilt-themes/deeppurple-amber.css";

  // Estilos personalizados para o botão
  .mat-header {
    --mdc-protected-button-container-color: #4a5bcc;
    --mdc-protected-button-label-text-color: #fff;
    --mat-protected-button-state-layer-color: #fff;
    height:24px !important;
    width:auto !important;
  }



  .small-form-field .mat-mdc-form-field-infix {
    min-height: auto;
  }

  .small-form-field {
    margin-bottom: 20px;
  }

  .nav-item {
    mat-form-field input::placeholder {
      opacity: 1 !important;
      color: whitesmoke !important;
    }
  }

  .mdc-list-item--with-one-line {
    border-radius: 0.25rem !important;
  }

  .mdc-list-item__content {
    align-self: flex-start !important;
    overflow: visible !important;
    width: 100%;
    border-radius: 0.25rem;

    padding: 0 !important;
  }

  .mdc-list-item {
    padding: 5px !important;
    --mdc-list-list-item-label-text-line-height: 16px;
  }

  .mdc-list-item__primary-text {
    overflow-wrap: break-word !important; /* Quebra a palavra quando necessário */
    word-wrap: break-word !important; /* Compatibilidade com navegadores mais antigos */
    white-space: normal !important; /* Permite quebra de linha em espaços */
  }

  .small-form-field .search-label {
    font-size: 0.9rem;
  }

  .small-form-field
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
    .mat-mdc-form-field-infix {
    padding-bottom: 4px;
    padding-top: 5px;
    font-size: 0.9rem;
  }

  .small-form-field
    .mdc-text-field:not(.mdc-text-field--disabled)
    .mdc-text-field__input {
    color: whitesmoke;
  }



  @media (max-width: 800px) {

    .nav-item {
      mat-form-field input::placeholder {
        opacity: 1 !important;
        color: #172b4d !important;
      }
    }

    
    .small-form-field
    .mdc-text-field:not(.mdc-text-field--disabled)
    .mdc-text-field__input {
      color: #172b4d;
  }
  }

  .small-form-field .mat-form-field-wrapper {
    font-size: 12px;
    padding-bottom: 0; /* Ajuste o padding conforme necessário */
  }

  .small-form-field .mat-mdc-icon-button.mat-mdc-button-base {
    padding: 0;
    width: 20px;
    height: 15px;
  }

  .small-form-field .mat-mdc-icon-button .mat-mdc-button-touch-target {
    width: 15px;
    height: 15px;
  }

  .small-form-field .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .backgroundHeader {
    background: linear-gradient(180deg, rgb(60 90 153), rgb(60 90 153));
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::before {
    border-bottom-color: whitesmoke;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
  }

  .mat-mdc-form-field .mdc-text-field {
    padding: 0 !important;
  }

  .skel {
    animation: loading 1.1s infinite linear;
    background: #dedfe1;
    background-image: -webkit-linear-gradient(
      to right,
      #dedfe1 0%,
      #f2f3f5 20%,
      #dedfe1 40%,
      #dedfe1 100%
    );
    background-image: linear-gradient(
      to right,
      #dedfe1 0%,
      #f2f3f5 20%,
      #dedfe1 40%,
      #dedfe1 100%
    );
    background-repeat: no-repeat;
  }


  .esmaecido {
    opacity: 1;
  }

  .esmaecido:hover {
    opacity: 0.8;
    transition: opacity 0.3s ease;
  }

  .paddingBotton {
    padding-bottom: 15px;
  }

  .tile {
    box-shadow: none !important;
  }

  .sections {
    padding: 5%;
  }

  .divider {
    padding-bottom: 20px;
  }

  .header1Posts {
    font-size: 2rem;
  }

  .margin-none {
    margin: 0 !important;
  }

  .conteudo-noticia p {
    font-size: 1.175rem;
    text-indent: 30px;
    color: black;
  }

  .conteudo-noticia > p > img {
    margin-left: -30px;
  }

  .conteudo-noticia img {
    max-width: 100%;
  }

  .conteudo-noticia p::first-letter {
    /* Use :first-letter if você precise muito de compatibilidade com IE 8 */
    /*color: darkblue;*/
    font-size: 130%;
  }

  .paddingContainer {
    margin-top: 20px;
    padding-bottom: 100px;
  }

  .paddingIcon {
    margin-right: 5px;
  }

  .padding-none {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    /*margin-top: 0;*/
  }

  .bg-dark-light {
    background: #ced4da2b;
  }

  html,
  body {
    height: 100%;
  }

  body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
